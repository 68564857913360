import "wicg-inert";

(function(window) {
    "use strict";

    const consentCookie = "__cookie_consent";
    var CookieMonster = function() {
        var _init = function() {
            var shouldRun = !_getCookie(consentCookie);
            var cookiebanner = document.getElementById("cookiebanner");
            if (shouldRun && cookiebanner) {
                cookiebanner.style.display = "block";
                document.getElementById("cookiebanner-overlay").style.display = "block";
                document
                    .querySelector(".js-modal-close-btn")
                    .setAttribute("disabled", "true");
                document.querySelector(".js-modal-close-btn").style.display = "none";
                trapFocus(cookiebanner);
                cookiebanner.focus();
                document.getElementById("mainContentBlock").setAttribute("inert", "");
            }

            document.body.addEventListener("click", _listener);
        };

        // check when links get clicked
        var _listener = function(event) {
            var element = event.target;
            if (!element) {
                return;
            }

            if (_hasClass(element, "js-cookie-settings")) {
                event.preventDefault();
                var cookiebanner = document.getElementById("cookiebanner");
                if (cookiebanner) {
                    cookiebanner.classList.toggle("superhidden");
                }
                _renderCookieModal();
                document.getElementById("mainContentBlock").setAttribute("inert", "");
            } else if (_hasClass(element, "js-cookie-accept")) {
                event.preventDefault();
                _setCookie(consentCookie, true);
                document.getElementById("cookiebanner").classList.add("superhidden");
                document.getElementById("cookieModal").classList.add("superhidden");
                document
                    .getElementById("cookiebanner-overlay")
                    .classList.add("superhidden");
                document.getElementById("mainContentBlock").removeAttribute("inert");
            } else if (_hasClass(element, "js-modal-close")) {
                event.preventDefault();
                _closeCookieModal();
                document
                    .getElementById("cookiebanner-overlay")
                    .classList.toggle("superhidden");
            } else if (_hasClass(element, "js-modal-close-btn")) {
                event.preventDefault();
                _closeCookieModalWithoutSave();
                document
                    .getElementById("cookiebanner-overlay")
                    .classList.toggle("superhidden");
            } else if (_hasClass(element, "js-cookie-performance")) {
                _updateCheckbox("performance");
            } else if (_hasClass(element, "js-cookie-marketing")) {
                _updateCheckbox("marketing");
            }
        };

        var _closeCookieModal = function() {
            if (
                _isCookieChecked("performance") === true &&
                _isCookieChecked("marketing") === true
            ) {
                _setCookie(consentCookie, true);
            }
            if (
                _isCookieChecked("performance") === true &&
                _isCookieChecked("marketing") === false
            ) {
                _setCookie(consentCookie, 2);
            }

            if (
                _isCookieChecked("marketing") === true &&
                _isCookieChecked("performance") === false
            ) {
                _setCookie(consentCookie, 3);
            }

            if (
                _isCookieChecked("marketing") === false &&
                _isCookieChecked("performance") === false
            ) {
                _setCookie(consentCookie, false);
            }

            var cookieModal = document.getElementById("cookieModal");
            cookieModal.classList.toggle("superhidden");
            document.getElementById("mainContentBlock").removeAttribute("inert");
        };

        var _closeCookieModalWithoutSave = function() {
            var cookieModal = document.getElementById("cookieModal");
            cookieModal.classList.toggle("superhidden");
            document.getElementById("mainContentBlock").removeAttribute("inert");
        };

        var _updateCheckbox = function(label) {
            var checkboxvar = document.getElementById(label);

            if (
                (checkboxvar.defaultChecked && !checkboxvar.checked) ||
                !checkboxvar.checked
            ) {
                checkboxvar.checked = false;
                checkboxvar.defaultChecked = false;
            } else {
                checkboxvar.checked = true;
            }
        };

        var _isCookieChecked = function(cookie) {
            var cookieId = document.getElementById(cookie);
            if (cookieId.checked === true || cookieId.defaultChecked) {
                return true;
            } else {
                return false;
            }
        };

        var _hasClass = function(element, selector) {
            return (
                element.className &&
                new RegExp("(\\s|^)" + selector + "(\\s|$)").test(element.className)
            );
        };

        var _getCookie = function(key) {
            if (!key) {
                return null;
            }
            return (
                decodeURIComponent(
                    document.cookie.replace(
                        new RegExp(
                            "(?:(?:^|.*;)\\s*" +
                            encodeURIComponent(key).replace(/[\-\.\+\*]/g, "\\$&") +
                            "\\s*\\=\\s*([^;]*).*$)|^.*$"
                        ),
                        "$1"
                    )
                ) || null
            );
        };

        var _setCookie = function(key, value) {
            var date = new Date();
            date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
            var expires = date.toUTCString();

            document.cookie =
                encodeURIComponent(key) +
                "=" +
                encodeURIComponent(value) +
                (expires ? "; expires=" + expires : "") +
                "; path=/";

            if (window.dataLayer) {
                window.dataLayer.push({ event: "cookie_refresh" });
            }
        };

        var _renderCookieModal = function() {
            //check if the modal was already opened before
            var cookieBanner = document.getElementById("cookiebanner");
            if (cookieBanner) {
                cookieBanner.classList.add("superhidden");
            }
            var cookieModal = document.getElementById("cookieModal");
            if (cookieModal) {
                cookieModal.style.display = "block";
                cookieModal.classList.remove("superhidden");
            }
            var cookieOverlay = document.getElementById("cookiebanner-overlay");
            cookieOverlay.style.display = "block";
            cookieOverlay.classList.remove("superhidden");

            var cookieGdpr = _getCookie(consentCookie);

            if (cookieGdpr === "true") {
                document.getElementById("performance").checked = true;
                _updateCheckbox("performance");
                document.getElementById("marketing").checked = true;
                _updateCheckbox("marketing");
            }
            if (cookieGdpr === "2") {
                document.getElementById("performance").checked = true;
                _updateCheckbox("performance");
            }
            if (cookieGdpr === "3") {
                document.getElementById("marketing").checked = true;
                _updateCheckbox("marketing");
            }
            trapFocus(cookieModal);
            cookieModal.focus();
        };

        return {
            init: _init,
        };
    };

    function trapFocus(element) {
        var focusableEls = element.querySelectorAll(
            'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
        );
        var firstFocusableEl = focusableEls[0];
        var lastFocusableEl = focusableEls[focusableEls.length - 1];
        var KEYCODE_TAB = 9;

        element.addEventListener("keydown", function(e) {
            var isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) {
                /* shift + tab */ if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } /* tab */ else {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }
            }
        });
    }

    var cookie = new CookieMonster();

    if (document.addEventListener) {
        document.addEventListener("DOMContentLoaded", cookie.init, false);
    }
})(window, undefined);

if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.webkitMatchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        null;
}
